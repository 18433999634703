import { createSlice, nanoid, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import BaseUrl from "../../../assets/utils/accessToken";
// import jsonData from '../../../assets/utils/CourseImprovements'
const initialState = {
  CourseReports: [],
  courseReportsStatus: "idle",
  coursesStatus: "idle",
  courseStatus: "idle",
  error: null,
  CourseReport: null,
  ProcessInstance: "null",
  id: null,
  currentRequestId: undefined,
  loading: 'idle',
};
export const fetchCourseReports = createAsyncThunk('CourseReport/fetchCourseReports', async (id, { getState, requestId, rejectWithValue }) => {
  const { currentRequestId, loading } = getState().CourseReport
  if (loading !== 'pending' || requestId !== currentRequestId) {
    console.log("****************stopped here**************",)

    return
  }

  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(BaseUrl + `/courseReporte/`,
      {
        headers: {
          'Authorization': `Token ${token}`
        }
      }
    )
    console.log("****************response.data**************", response.data)
    return response.data
  } catch (err) {
    let error = err // cast the error for access
    if (!error.response) {
      throw err
    }
    // We got validation errors, let's return those so we can reference in our component and set form errors
    return rejectWithValue(error.response.data)
  }
})

export const fetchCourseReportById = createAsyncThunk(
  "CourseReport/fetchCourseReportById",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(BaseUrl + `/courseReporte/${id}/`, {
        headers: {
          Authorization: `token ${token}`,
        },
      });
      ///console.log(response.data, 'response.data fetch course by id')

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);



export const addNewCourseReport = createAsyncThunk(
  "CourseReport/addNewCourseReport",
  async (initialCourseReport, { rejectWithValue }) => {
    try {
      console.log("initial CourseReport", initialCourseReport);
      const token = localStorage.getItem("token");
      const response = await axios.post(
        BaseUrl + `/courseReporte/create/`,
        initialCourseReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const StartProcessInstance = createAsyncThunk(
  "CourseReport/StartProcessInstance",
  async (key, { rejectWithValue }) => {
    console.log(
      "axios.defaults.headers :" +
      JSON.stringify(
        axios.defaults.headers.common["Access-Control-Allow-Origin"]
      )
    );

    delete axios.defaults.headers.common["Access-Control-Allow-Origin"];

    console.log();
    const token = localStorage.getItem("token");
    const response = await axios.post(
      `http://152.228.211.15:8080/engine-rest/process-definition/key/${key}/start`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "http://152.228.211.15:8080",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        },
      }
    );
    console.log(JSON.stringify(response.data));

    // delete axios.defaults.headers.common["Authorization"];
    return response.data;
  }
);
export const deleteCourseReport = createAsyncThunk(
  "CourseReport/deleteCourseReport",
  async (id, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.delete(
        BaseUrl + `/courseReporte/${id}/delete/`,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(response.data, " delete response.data");

      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const CourseReportUpdate = createAsyncThunk(
  "CourseReport/CourseReporttUpdate",
  async (data, { rejectWithValue }) => {
    console.log(data, "updatedcourseReport");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/courseReporte/${data.id}/edit/`,
        data.updatedCourseReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedcourseReport");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);

export const UpdateCourseReport = createAsyncThunk(
  "CourseReport/UpdateCourseReport",
  async (data, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/courseReporte/update/${data.id}/`,
        data.updatedCourseReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response);
    }
  }
);

export const updateDifferencesInEvaluation = createAsyncThunk(
  "CourseReport/updateDifferencesInEvaluation",
  async (data, { rejectWithValue }) => {
    console.log(data, "updateDifferencesInEvaluation");
    try {
      const token = localStorage.getItem("token");
      const response = await axios.put(
        BaseUrl + `/courseReporte/differences_in_evaluation/${data.id}/update/`,
        data.updatedCourseReport,
        {
          headers: {
            Authorization: `token ${token}`,
          },
        }
      );
      console.log(data, "updatedcourseReport");
      console.log(response.data, "response.data");
      return response.data;
    } catch (err) {
      let error = err;
      if (!error.response) {
        throw err;
      }
      console.log("error.response from inside the CAT", error.response);
      return rejectWithValue(error.response);
    }
  }
);
export const AffectCoordinatorToCourseReport = createAsyncThunk(
  'courseReport/AffectCoordinatorToCourseReport',
  async (data, { rejectWithValue }) => {
    const token = localStorage.getItem('token')
    try {
      const response = await axios.put(BaseUrl + `/courseReporte/update/${data.id}/`, data.data, {
        headers: {
          'Authorization': `token ${token}`
        }
      })
      //  console.log(response.data, 'response.data')
      return response.data

    } catch (err) {
      let error = err // cast the error for access
      if (!error.response) {
        throw err
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      // console.log('error.response from inside the CAT', error.response)
      return rejectWithValue(error.response)
    }
  }
)


const CourseReportSlice = createSlice({
  name: " CourseReport",
  initialState,
  extraReducers: {},
  reducers: {
    getCourseId: (state, action) => {
      state.id = action.payload;
    },
    CourseReportUpdated(state, action) {
      const {
        id,
        course,
        stateObject,
        due_date,
        sentTO,
        process_identification,
      } = action.payload;
      const existingCourseReport = state.CourseReports.find(
        (CourseReport) => CourseReport.id === id
      );
      if (existingCourseReport) {
        existingCourseReport.course = course;
        existingCourseReport.stateObject = stateObject;
        existingCourseReport.due_date = due_date;
        existingCourseReport.sentTO = sentTO;
        existingCourseReport.process_identification = process_identification;
      }
    },
  },
  extraReducers: {
    [fetchCourseReports.pending]: (state, action) => {
      if (state.loading === 'idle') {
        state.loading = 'pending'
        state.courseReportsStatus = 'loading'
        state.currentRequestId = action.meta.requestId
      }
    },
    [fetchCourseReports.fulfilled]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.courseReportsStatus = 'succeeded'
        state.CourseReports = action.payload
        state.currentRequestId = undefined
      }
    },
    [fetchCourseReports.rejected]: (state, action) => {
      const { requestId } = action.meta
      if (
        state.loading === 'pending' &&
        state.currentRequestId === requestId
      ) {
        state.loading = 'idle'
        state.courseReportsStatus = 'failed'
        state.error = action.error
        state.currentRequestId = undefined
      }
    },

    [fetchCourseReportById.pending]: (state, action) => {
      state.courseStatus = "loading";
    },
    [fetchCourseReportById.fulfilled]: (state, action) => {
      state.courseStatus = "succeeded";
      state.CourseReport = action.payload;
    },
    [fetchCourseReportById.rejected]: (state, action) => {
      state.courseStatus = "failed";
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },

    [StartProcessInstance.pending]: (state, action) => {
      state.courseStatus = "loading";
    },
    [StartProcessInstance.fulfilled]: (state, action) => {
      state.courseStatus = "succeeded";
      state.ProcessInstance = action.payload;
    },
    [StartProcessInstance.rejected]: (state, action) => {
      state.courseStatus = "failed";
      state.error = action.payload;
    },

    [addNewCourseReport.fulfilled]: (state, action) => {
      state.CourseReports.push(action.payload);
    },

    [addNewCourseReport.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [deleteCourseReport.fulfilled]: (state, action) => { },
    [deleteCourseReport.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },
    [CourseReportUpdate.fulfilled]: (state, action) => { },
    [CourseReportUpdate.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },



    [updateDifferencesInEvaluation.fulfilled]: (state, action) => { },
    [updateDifferencesInEvaluation.rejected]: (state, action) => {
      if (action.payload) {
        state.error = action.payload;
      } else {
        state.error = action.error;
      }
    },


  },
});

export const { getCourseId, CourseReportAdded, CourseReportUpdated } =
  CourseReportSlice.actions;

export default CourseReportSlice.reducer;
