import React, { useState, useEffect, Fragment } from "react";

import cardStyles from "assets/jss/material-dashboard-pro-react/components/cardStyle.js";

import { useDispatch, useSelector } from "react-redux";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "../../../../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import styles1 from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";

import { Tabs } from "antd";
import "antd/dist/antd.css";

import { useTranslation } from "react-i18next";
// import "./improvement.css"

import {
  fetchAnnualProgramReportById,
  UpdateAnnualProgramReport,
} from "../../../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";

const useStyles3 = makeStyles(styles1);
const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    width: "fit-content",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    "& svg": {
      margin: theme.spacing(1.5),
    },
    "& hr": {
      margin: theme.spacing(0, 0.5),
    },
  },
}));
const useStyles1 = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));
const useStyles2 = makeStyles(styles);
const useCardStyles = makeStyles(cardStyles);

export default function Identification({ AnnualProgramReport }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const classes1 = useStyles1();
  const classes2 = useStyles2();
  const classes3 = useStyles3();
  const dispatch = useDispatch();

  const callback = (key) => {
    console.log(key);
  };

  return (
    <div>
      <div style={{ textAlign: "center", color: "#000080", fontSize: "22px" }}>
        <p>Annual Program Report</p>
      </div>
      <table style={{ margin: "0% 5% 5% 5%", border: "1px solid lightgrey" }}>
        <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            Program Name
          </td>
          <td
            style={{
              width: "80%",
              padding: "2%",
              border: "1px solid lightgrey",
            }}
          >
            {AnnualProgramReport &&
              AnnualProgramReport.programs &&
              AnnualProgramReport.programs[0] &&
              AnnualProgramReport.programs[0].program_degree_name}
          </td>
        </tr>
        <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            Qualification Level
          </td>
          <td
            style={{
              width: "70%",
              padding: "2%",
              border: "1px solid lightgrey",
            }}
          >
            {AnnualProgramReport?.programs?.[0]?.qualification_level?.name || AnnualProgramReport?.programs?.[0]?.qualification_level}
          </td>
        </tr>

        <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            Department
          </td>
          <td
            style={{
              width: "70%",
              padding: "2%",
              border: "1px solid lightgrey",
            }}
          >
            {AnnualProgramReport &&
              AnnualProgramReport.programs &&
              AnnualProgramReport.programs[0] &&
              AnnualProgramReport.programs[0].department &&
              AnnualProgramReport.programs[0].department[0] &&
              AnnualProgramReport.programs[0].department[0].name}
          </td>
        </tr>

        {/* <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            {" "}
            College{" "}
          </td>
          <td style={{ width: "70%", padding: "2%" }}>
            {AnnualProgramReport &&
              AnnualProgramReport.programs &&
              AnnualProgramReport.programs[0] &&
              AnnualProgramReport.programs[0].department &&
              AnnualProgramReport.programs[0].department[0] &&
              AnnualProgramReport.programs[0].college[0].name}
          </td>
        </tr> */}

        <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            Institution
          </td>
          <td
            style={{
              width: "70%",
              padding: "2%",
              border: "1px solid lightgrey",
            }}
          >
            {AnnualProgramReport &&
              AnnualProgramReport.programs &&
              AnnualProgramReport.programs[0] &&
              AnnualProgramReport.programs[0].institution &&
              AnnualProgramReport.programs[0].institution.name}
          </td>
        </tr>

        <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            Academic Year
          </td>
          <td
            style={{
              width: "70%",
              padding: "2%",
              border: "1px solid lightgrey",
            }}
          >
            {AnnualProgramReport &&
              AnnualProgramReport.programs &&
              AnnualProgramReport.programs[0] &&
              AnnualProgramReport.programs[0].academicYear &&
              AnnualProgramReport.programs[0].academicYear.name}
          </td>
        </tr>

        <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            Main Location{" "}
          </td>
          <td style={{ width: "70%", padding: "2%" }}>
            {AnnualProgramReport &&
              AnnualProgramReport.programs &&
              AnnualProgramReport.programs[0] &&
              AnnualProgramReport.programs[0].main_locations}
          </td>
        </tr>

        <tr>
          <td style={{ paddingLeft: "5px", border: "1px solid lightgrey" }}>
            Branches offering the Program{" "}
          </td>
          <td
            style={{
              width: "70%",
              padding: "2%",
              border: "1px solid lightgrey",
            }}
          >
            <ul>
              {AnnualProgramReport &&
                AnnualProgramReport.programs &&
                AnnualProgramReport.programs[0] &&
                AnnualProgramReport.programs[0].branches &&
                AnnualProgramReport.programs[0].branches.map((el) => (
                  <li>{el.name}</li>
                ))}
            </ul>
          </td>
        </tr>
      </table>
    </div>
  );
}
