import { calculateYearsBetweenExperienceDates } from "../../../../assets/utils/helperFunction";
import { formatDate } from "../../../../assets/utils/helperFunction";
export const updateReportBody = (
  setReportBody,
  criterionKey,
  sectionKey,
  fieldKey,
  newContent
) => {
  setReportBody((prevReport) => ({
    ...prevReport,
    [criterionKey]: {
      ...prevReport[criterionKey],
      [sectionKey]: {
        ...prevReport[criterionKey][sectionKey],
        [fieldKey]: newContent,
      },
    },
  }));
};

export const Analysis = [
  {
    id: 1,
    subject: "Curriculum Breadth",
    descripton:
      "The curriculum provides strong coverage of core engineering concepts, but there is limited exposure to emerging technologies such as artificial intelligence and data analytics, which are increasingly important in modern engineering practice.",
    type: "concern",
    assignees: [
      { name: "John", id: "1" },
      { name: "Jane", id: "2" },
    ],
    startdate: "01/01/2025",
    enddate: "12/3/2025",
    actions: [
      {
        id: "1",
        feedbackId: "1",
        subject: "action 1",
        descripton: "descripton action 1",
        date: "01/01/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "2",
        feedbackId: "1",
        subject: "action 2",
        descripton: "descripton action 2",
        date: "01/02/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "3",
        feedbackId: "1",
        subject: "action 3",
        descripton: "descripton action 3",
        date: "01/05/2025",
        assignees: [{ name: "Jane", id: "2" }],
      },
    ],
  },
  {
    id: 2,
    subject: "Outcomes Assessment",
    descripton:
      "The program has not adequately demonstrated a structured and comprehensive approach to assessing student outcomes. There is no clear evidence that student performance is systematically evaluated against the stated program outcomes.",
    type: "deficiency",
    assignees: [
      { name: "John", id: "1" },
      { name: "Jane", id: "2" },
    ],
    startdate: "01/01/2025",
    enddate: "12/3/2025",
    actions: [
      {
        id: "4",
        feedbackId: "2",
        subject: "action 1",
        descripton: "descripton action 1",
        date: "01/01/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "5",
        feedbackId: "2",
        subject: "action 2",
        descripton: "descripton action 2",
        date: "01/02/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "6",
        feedbackId: "2",
        subject: "action 3",
        descripton: "descripton action 3",
        date: "01/05/2025",
        assignees: [{ name: "Jane", id: "2" }],
      },
    ],
  },
  {
    id: 3,
    subject: "Advising and Mentorship",
    descripton:
      "While academic advising is available, there is a weakness in the advising process, as students have expressed concerns about the lack of personalized guidance and mentorship to help them navigate their academic and career pathways.",
    type: "weakness",
    assignees: [
      { name: "John", id: "1" },
      { name: "Jane", id: "2" },
    ],
    startdate: "01/01/2025",
    enddate: "12/3/2025",
    actions: [
      {
        id: "7",
        feedbackId: "3",
        subject: "action 1",
        descripton: "descripton action 1",
        date: "01/01/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "8",
        feedbackId: "3",
        subject: "action 2",
        descripton: "descripton action 2",
        date: "01/02/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "9",
        feedbackId: "3",
        subject: "action 3",
        descripton: "descripton action 3",
        date: "01/05/2025",
        assignees: [{ name: "Jane", id: "2" }],
      },
    ],
  },
  {
    id: 4,
    subject: "Strong Industry Partnerships",
    descripton:
      "The program has established strong partnerships with local industries, offering students valuable internship opportunities and industry-sponsored projects that enhance their professional readiness.",
    type: "strength",
    assignees: [
      { name: "John", id: "1" },
      { name: "Jane", id: "2" },
    ],
    startdate: "01/01/2025",
    enddate: "12/3/2025",
    //feedback of type strength does not have actions associated with it
    actions: [],
  },
  {
    id: 5,
    subject: "Expand Emerging Technology Offerings",
    descripton:
      "To keep pace with industry trends, the program should consider introducing electives or modules focused on emerging areas such as artificial intelligence, robotics, and renewable energy.",
    type: "recommendation for improvement",
    assignees: [
      { name: "John", id: "1" },
      { name: "Jane", id: "2" },
    ],
    startdate: "01/01/2025",
    enddate: "12/3/2025",
    actions: [
      {
        id: "13",
        feedbackId: "5",
        subject: "action 1",
        descripton: "descripton action 1",
        date: "01/01/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "14",
        feedbackId: "5",
        subject: "action 2",
        descripton: "descripton action 2",
        date: "01/02/2025",
        assignees: [{ name: "John", id: "1" }],
      },
      {
        id: "15",
        feedbackId: "5",
        subject: "action 3",
        descripton: "descripton action 3",
        date: "01/05/2025",
        assignees: [{ name: "Jane", id: "2" }],
      },
    ],
  },
];

//////////////////////////////////////////////////////////////////////

const deriveBackgroundInformation = (program) => {
  return {
    ContactInformation: {
      name: `${program.person_detail?.[0]?.first_name} ${program.person_detail?.[0]?.last_name}`,
      department: program.department_detail?.[0]?.name,
      college: program.college_detail?.[0]?.name,
      university: program.university_detail?.[0]?.name,
      //-----------------------------------------------------//
      address: program.coordinators_detail?.[0]?.work_location ?? "",
      phone: program.coordinators_detail?.[0]?.work_phone ?? "",
      email: program.coordinators_detail?.[0]?.work_email ?? "",
      fax: program.coordinators_detail?.[0]?.work_phone ?? "",
    },
    // major changes are the actions (with "high priority", and whose states are "done") taken to address feedback of last general review
    ProgramHistory: {
      //-----------------------------------------------------//
      lastReviewDate: formatDate(program.last_activity),
      //-----------------------------------------------------//
      majorChanges: [],
      history: program.history,
      description: ``,
    },
    Options: {
      //-----------------------------------------------------//
      options: program.specialization_details?.length
        ? program.specialization_details?.map((spec) => spec.name)
        : [],
      tracks: program.major_tracks_details?.length
        ? program.major_tracks_details?.map((track) => track.pathway)
        : [],
      description: ``,
    },
    ProgramDeliveryModes: {
      //-----------------------------------------------------//
      // modes: program.deliveryModes ?? [],
      modes: program?.courses
        ?.map((course) =>
          course?.mode_of_instructions?.map(
            (mode) => mode.mode_of_instruction__name
          )
        )
        .flat().length
        ? program?.courses
            ?.map((course) =>
              course?.mode_of_instructions?.map(
                (mode) => mode.mode_of_instruction__name
              )
            )
            .flat()
        : [],
      description: ``,
    },
    ProgramLocations: {
      // locations: program.accredited_campus_location?.length
      //   ? program.accredited_campus_location.map((loc) => loc.location)
      //   : [],
      locations: program.accredited_campus_location,
      description: ``,
    },
    PublicDisclosure: {
      //-----------------------------------------------------//
      publicDisclosure:
        program.program_disclosure_link ??
        program.programSpec_detail?.program_disclosure_link ??
        "university website",
      description: ``,
    },
    PreviousEvaluations: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion1 = (program) => {
  return {
    StudentAdmissions: {
      // Program admission requirements
      studentAdmissionRequirements: program.admissions_detail?.length
        ? program.admissions_detail?.map((req) => req.title)
        : [],
      description: ``,
    },
    EvaluatingStudentPerformance: {
      prerequisites: program.courses?.length
        ? program.courses?.map((course) => ({
            key: course.id,
            title: course.title,
            children: course.prerequisites_details?.length
              ? course.prerequisites_details?.map((req) => ({
                  key: req.id,
                  title: req.requisite,
                }))
              : [],
          }))
        : [],
      description: ``,
    },
    TransferStudentsAndTransferCourses: {
      //-----------------------------------------------------//
      transferStudentsAcceptanceRequirements: program.programSpec_detail
        ?.acceptance_details?.length
        ? program.programSpec_detail?.acceptance_details?.map((req) => req.name)
        : [],
      //-----------------------------------------------------//
      transferCreditAcceptanceRequirements: program.programSpec_detail
        ?.transfer_credits_details?.length
        ? program.programSpec_detail?.transfer_credits_details?.map(
            (req) => req.name
          )
        : [],
      description: ``,
    },
    AdvisingAndCareerGuidance: {
      GuidanceAndOrientationProgramsForNewStudents:
        program.guidance_for_new_students ??
        program.programSpec_detail?.guidance_for_new_students ??
        "",
      StudentCounselingServices:
        program.student_counseling ??
        program.programSpec_detail?.student_counseling ??
        "",
      SpecialSupport:
        program.special_support ??
        program.programSpec_detail?.special_support ??
        "",
      description: ``,
    },
    //-----------------------------------------------------//
    WorkInLieuOfCourses: {
      WorkInLieuOfCourses:
        program.work_in_lieu_of_courses ??
        program.programSpec_detail?.work_in_lieu_of_courses ??
        "",
      description: ``,
    },
    GraduationRequirements: {
      programName: program.program_degree_name,
      awardedDegreeName: program.programSpec_detail?.degree_awarded,
      graduationRequirements: program.graduations_detail?.length
        ? program.graduations_detail?.map((req) => req.title)
        : [],
      description: ``,
    },
    RecordsOfStudentWorkOrTranscripts: {
      //-----------------------------------------------------//
      transcriptsFiles: [],
      attachments: [],
      description: ``,
    },
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion2 = (program) => {
  return {
    MissionStatement: {
      institutionMissionStatement: program?.university_detail?.[0].mission,
      collegeMissionStatement: program?.college_detail?.[0].mission,
      programMissionStatement: program?.mission,
    },
    ProgramEducationalObjectives: {
      //-----------------------------------------------------//
      PEOs: program.peos_details?.length
        ? program.peos_details.map((peo) => `${peo.code}:  ${peo.name}`)
        : [],
      publicDisclosure: program.programSpec_detail?.program_disclosure_link ?? "university website",
      programName: program?.program_degree_name,
      description: ``,
    },
    ConsistencyOfTheProgramEducationalObjectivesWithTheMissionOfTheInstitution:
      {
        description: ``,
      },
    ProgramConstituencies: {
      //-----------------------------------------------------//
      description: ``,
      programName: program?.program_degree_name,
    },
    ProcessForReviewOfTheProgramEducationalObjectives: {
      description: ``,
      // add recent changes to PEOs
    },
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion3 = (program) => {
  return {
    StudentOutcomes: {
      //-----------------------------------------------------//
      PLOs: program?.plos_details?.length
        ? program.plos_details.map((plo) => `${plo.code}:  ${plo.name}`)
        : [],
      // each PLO should have an array of appropriate General Criteria Student Outcomes, if the  PLO does not address any General Criteria, the list should be empty
      programName: program?.program_degree_name,
      publicDisclosure: program.programSpec_detail?.program_disclosure_link ?? "university website",
      description: ``,
    },
    RelationshipOfStudentOutcomesToProgramEducationalObjectives: {
      //a list of the PEOs codes
      //-----------------------------------------------------//
      PEOs: program.peos_details?.length
        ? program.peos_details.map((peo) => peo.code)
        : [],
      //the mapping array should follow the following format:
      PLOs_PEOs_mapping: program.peosplos_mapping_details?.length
        ? program.peosplos_mapping_details.map((plo) => {
            let res = {};
            let obj = {};
            for (let peo of program.peos_details) {
              plo.weight_plo?.map((p) => p?.peo?.id)?.includes(peo.id)
                ? (obj[peo.code] = true)
                : (obj[peo.code] = false);
            }
            res = { PLO: plo.name, ...obj };
            return res;
          })
        : [],
      PLOs_Indicators_mapping: program.peosplos_mapping_details?.length
        ? program.peosplos_mapping_details.map((plo) => {
            const indicators = plo.plo_indicators?.map((ind) => ind.name);
            return { PLO: plo.name, indicators };
          })
        : [],
      description: ``,
    },
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion4 = (program, programsByCode) => {
  function get_plo_indicators_scores_per_semester(input) {
    const output = [];

    input.forEach((semester) => {
      const semesterKey = `${semester.semester_details[0].name}_${semester.semester_details[0].academicYear__name}`;

      semester.peosplos_mapping_details.forEach((plo) => {
        let ploOutput = output.find((p) => p.plo_code === plo.code);

        if (!ploOutput) {
          ploOutput = {
            plo_code: plo.code,
            plo_indicators_scores_per_semester: [],
          };
          output.push(ploOutput);
        }

        plo.plo_indicators.forEach((indicator) => {
          let indicatorOutput =
            ploOutput.plo_indicators_scores_per_semester.find(
              (i) => i.plo_indicator_code === indicator.code
            );

          if (!indicatorOutput) {
            indicatorOutput = {
              plo_indicator_code: indicator.code,
              name: indicator.name,
              target: indicator.target,
            };
            ploOutput.plo_indicators_scores_per_semester.push(indicatorOutput);
          }

          indicatorOutput[semesterKey] = indicator.score;
        });
      });
    });

    return output;
  }

  const plo_indicators_scores_per_semester =
    get_plo_indicators_scores_per_semester(programsByCode);

  return {
    StudentOutcomes: {
      // if assessment processes are the assessment methods
      // assessmentMethods: [
      //   { assessmentMethod: "oral-exam", description: "" },
      //   { assessmentMethod: "focus-group", description: "" },
      // ],
      assessmentFrequency: "",
      PLOs_courses_mapping: program.peosplos_mapping_details?.length
        ? program.peosplos_mapping_details.map((plo) => {
            let supportedCourses = new Set();
            // Collect all supported courses for this PLO
            for (let ind of plo.plo_indicators) {
              ind.weight_clos.forEach((clo) => {
                if (clo.clo?.course?.id) {
                  supportedCourses.add(clo.clo.course.id);
                }
              });
            }
            // Create the mapping object
            let courseMapping = {};
            for (let course of program.courses) {
              courseMapping[course.code] = supportedCourses.has(course.id);
            }
            return { PLO: plo.name, ...courseMapping };
          })
        : [],
      // the courses array should include only the course codes [1010, 1015, 1011]
      courses: program.courses?.map((course) => course.code) || [],
      // each PLO object should include the PLO's id code, name , target (expected level of attainment), the results of its evaluation
      PLOs: program.peosplos_mapping_details?.map((plo) => {
        const plo_plo_indicators_scores_per_semester =
          plo_indicators_scores_per_semester?.find(
            (p) => p.plo_code === plo.code
          );
        return {
          id: plo.id,
          code: plo.code,
          name: plo.name,
          target: plo.target,
          score: plo.score,
          evaluationTable: plo.plo_indicators?.map((ind) => {
            return {
              id: ind.id,
              performanceIndicator: ind.name,
              EducationalStrategies: [
                ...new Set(ind.weight_clos?.map((clo) => clo.clo.course.code)),
              ],
              MethodsOfAssessment: [
                ...new Set(
                  ind.weight_clos
                    ?.map((clo) =>
                      clo.clo.planned_assessment_methods?.map(
                        (am) => am.assessment_name
                      )
                    )
                    .flat()
                ),
              ],
              WhereSummativeDataAreCollected: [
                ...new Set(
                  ind.weight_clos
                    ?.map((clo) =>
                      clo.clo.assessment_methods?.map((am) => am.name)
                    )
                    .flat()
                ),
              ],
              LengthOfAssessmentCycle: Math.max(
                ...[
                  ...new Set(
                    ind.weight_clos
                      ?.map((clo) =>
                        clo.clo.planned_assessment_methods?.map(
                          (am) => am.length_assessment_cycle
                        )
                      )
                      .flat()
                  ),
                ]
              ),
              YearSemesterOfDataCollection: "",
              TargetForPerformance: ind.target,
            };
          }),
          barChart: {
            barChartData:
              plo_plo_indicators_scores_per_semester?.plo_indicators_scores_per_semester ??
              [],
            barChartKeys: programsByCode?.map(
              (semester) =>
                `${semester.semester_details[0].name}_${semester.semester_details[0].academicYear__name}`
            ),
          },
          // [
          //   {
          //     name: "Problem statement shows understanding of the problem",
          //     target: 90,
          //     2015: 80,
          //     2018: 85,
          //     2021: 90,
          //   },
          //   {
          //     name: "Problem solution is appropriate and within reasonable constraints",
          //     target: 90,
          //     2015: 80,
          //     2018: 85,
          //     2021: 90,
          //   },
          // ],
          evaluationResults: `For summative assessment (end of program), the decision was made to
              focus on the direct assessment for all indicators. Summative data
              for Indicators #1 were collected in the Engineering Design I course
              (EGR 4090) where students are asked to develop their statement of
              the problem and project planning documentation. For Indicator #2 the
              assessment was completed in the second semester design course (EGR
              4092) as a part of the final assessment of the course. For Indicator
              #3 faculty created an examination administered in EGR 3030 which
              required students to apply mathematical principles to model
              equations to achieve solutions. For Indicator #4 the assessment was
              completed in the second semester design course (EGR 4092) as a part
              of the final assessment of student performance on the senior project
              report using a rubric for the indicator.`,
        };
      }),
      description: ``,
    },
    ContinuousImprovement: {
      description: ``,
    },
    AdditionalInformation: {
      description: ``,
    },
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion5 = (program) => {
  return {
    ProgramCurriculum: {
      //If there is more than one curricular path or option for a program, a separate programCurriculumTable should be provided for each path or option.
      programCurriculumTable: program.courses?.map((course) => {
        let courseTitle = `${course.department_details?.[0]?.name} ${course.code} - ${course.title} `;
        let topicsCredits = course?.course_content_details?.reduce(
          (acc, topic) => {
            acc[topic.name] = topic.topic_hours;
            return acc;
          },
          {}
        );
        return {
          id: course.id,
          title: courseTitle,
          prerequisites: course.prerequisites_details,
          corequisites: course.co_requisites_details,
          // mathBasicSciencesCredits: "10",
          // engineeringTopicsCredits: "3",
          // otherCredits: "5",
          ...topicsCredits,
          credits: "14",
          type:
            course.category === "Required"
              ? "R"
              : course.category === "Elective"
              ? "E"
              : "SE",
          courseLastTwoTerms: [],
          maximumSectionEnrollment: "...",
          yearTerm: "",
        };
      }),
      subjects: [
        ...new Set(
          program.courses
            ?.map((course) =>
              course.course_content_details?.map((topic) => topic.name)
            )
            .flat()
            .filter(Boolean)
        ),
      ],
      description: ``,
    },
    CourseSyllabi: {},
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion6 = (program) => {
  return {
    FacultyQualifications: {
      description: ``,
      FacultyQualificationsTable: program.courses
        ?.map((course) => {
          return course?.instructors?.map((instructor) => {
            let registrations = instructor.registrations?.map(
              (registration) =>
                `${registration.code} (${registration.organization})`
            );
            let certificationtions = instructor.registrations?.map(
              (registration) => `${registration.code} ${registration.issued_by}`
            );
            let registrationsAndCertifications =
              registrations?.concat(certificationtions)?.join(", ") || "None";
            let academiaExperienceYears =
              instructor.teaching_and_academic_experience
                ?.map((exp) => {
                  return calculateYearsBetweenExperienceDates(
                    exp.start_date,
                    exp.end_date,
                    exp.until_now
                  );
                })
                .reduce((sum, years) => sum + years, 0) || 0;
            let nonAcademiaExperienceYears =
              instructor.professionalExperience
                ?.map((exp) => {
                  return calculateYearsBetweenExperienceDates(
                    exp.start_date,
                    exp.end_date,
                    exp.until_now
                  );
                })
                .reduce((sum, years) => sum + years, 0) || 0;
            let insideInstitutionExperience =
              instructor.teaching_and_academic_experience.find(
                (exp) =>
                  exp.until_now &&
                  exp.institution === program.university_detail?.[0]?.name
              );
            let instructorObj = {
              id: instructor.id,
              name: `${instructor.first_name} ${instructor.last_name}`,
              degree: `${instructor.diploma} ${instructor.diploma_date}`,
              rank: instructor.rank,
              AcademicAppointmentType: instructor.type_of_academic_appointment,
              employmentStatus: instructor.full_time ? "FT" : "PT",
              nonAcademiaExperienceYears: nonAcademiaExperienceYears,
              academiaExperienceYears: academiaExperienceYears,
              insideInstitutionExperienceYears:
                calculateYearsBetweenExperienceDates(
                  insideInstitutionExperience?.start_date,
                  insideInstitutionExperience?.end_date,
                  true
                ),
              ProfessionalRegistrationCertification:
                registrationsAndCertifications,
              ProfessionalOrganizationsActivityLevel: "...",
              ProfessionalDevelopmentActivityLevel: "...",
              industryConsultingORSummerWorkActivityLevel: "...",
            };
            return instructorObj;
          });
        })
        .flat(),
    },
    FacultyWorkload: {
      FacultyWorkloadTable: program.courses
        ?.map((course) => {
          return course?.instructors?.map((instructor) => {
            return {
              id: instructor.id,
              name: `${instructor.first_name} ${instructor.last_name}`,
              employmentStatus: instructor.full_time ? "FT" : "PT",
              // classes For the academic year for which the self-study is being prepared.
              classes: instructor.courses_taught?.map(
                (course) =>
                  `${course.course__code} (${course.course__credit_hours}) ${course.course__semester__name}/ ${course.course__semester__academicYear__name}`
              ),
              teachingActivitiesPercentage: "...%",
              researchOrScholarshipActivitiesPercentage: "...%",
              otherActivitiesPercentage: "...%",
              timePercentageDevotedToTheProgram: "...%",
            };
          });
        })
        .flat(),
      description: ``,
    },
    FacultySize: {
      description: ``,
    },
    ProfessionalDevelopment: {
      description: ``,
    },
    AuthorityAndResponsibilityOfFaculty: {
      description: ``,
    },
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion7 = (program) => {
  return {
    OfficesClassroomsLaboratories: {
      officesClassroomsLaboratoriesFacilities: program.courses
        ?.map((course) =>
          course.facilities_required?.filter(
            (facility) =>
              facility.item__name &&
              (facility.item__name.toLowerCase().includes("classrooms") ||
                facility.item__name.toLowerCase().includes("laboratories") ||
                facility.item__name.toLowerCase().includes("offices"))
          )
        )
        ?.flat()
        .filter(Boolean),
      description: ``,
    },
    ComputingResources: {
      computingResources: program.courses
        ?.map((course) =>
          course.facilities_required?.filter(
            (facility) => facility.item__computing_resource
          )
        )
        ?.flat(),
      description: ``,
    },
    Guidance: {
      description: ``,
    },
    MaintenanceAndUpgradingOfFacilities: {
      description: ``,
    },
    LibraryServices: {
      libraryServices: program.courses
        ?.map((course) =>
          course.learning_resources?.filter((resource) =>
            resource.resource_type__name?.toLowerCase()?.includes("library")
          )
        )
        ?.flat()
        .filter(Boolean),
      description: ``,
    },
    OverallCommentsOnFacilities: {
      description: ``,
    },
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

const deriveCriterion8 = (program) => {
  return {
    Leadership: {
      description: ``,
    },
    ProgramBudgetAndFinancialSupport: {
      description: ``,
    },
    Staffing: {
      description: ``,
    },
    FacultyHiringAndRetention: {
      description: ``,
    },
    SupportOfFacultyProfessionalDevelopment: {
      description: ``,
    },
    Analysis: {
      feedback: Analysis,
      description: ``,
    },
  };
};

export const generateABETReport = (program, programsByCode) => {
  return {
    programId: program.id,
    programName: program.program_degree_name,
    institutionName: program.university_detail[0]?.name,
    location: program.accredited_campus_location?.location,
    BACKGROUNDINFORMATION: deriveBackgroundInformation(program),
    CRITERION1_STUDENTS: deriveCriterion1(program),
    CRITERION2_PROGRAMEDUCATIONALOBJECTIVES: deriveCriterion2(program),
    CRITERION3_STUDENTOUTCOMES: deriveCriterion3(program),
    CRITERION4_CONTINUOUSIMPROVEMENT: deriveCriterion4(program, programsByCode),
    CRITERION5_CURRICULUM: deriveCriterion5(program),
    CRITERION6_FACULTY: deriveCriterion6(program),
    CRITERION7_FACILITIES: deriveCriterion7(program),
    CRITERION8_INSTITUTIONALSUPPORT: deriveCriterion8(program),
  };
};

//////////////////////////////////////////////////////////////////////
const input = [
  {
    id: 1,
    code: "MEC-ENGINEER",
    program_degree_name: "Mechanical Engineering",
    semester: [
      {
        semester__id: 1,
        semester__name: "fall",
        academicYear__id: 1,
        academicYear__name: "2023/2024",
      },
    ],
    PLOs: [
      {
        id: 1,
        name: "P1",
        code: "P1",
        score: 20,
        target: 30.0,
        plo_indicators: [
          {
            id: 1,
            name: "PI11",
            code: "PI11",
            weight: 30,
            target: 20.0,
            score: 10,
          },
          {
            id: 2,
            name: "PI12",
            code: "PI12",
            weight: 70,
            target: 60.0,
            score: 50,
          },
        ],
      },
      {
        id: 2,
        name: "P2",
        code: "P2",
        score: null,
        target: 20.0,
        plo_indicators: [
          {
            id: 3,
            name: "PI21",
            code: "PI21",
            weight: 30,
            target: 20.0,
            score: 15,
          },
        ],
      },
      {
        id: 3,
        name: "P3",
        code: "P3",
        score: null,
        target: 50.0,
        plo_indicators: [
          {
            id: 4,
            name: "PI31",
            code: "PI31",
            weight: 40,
            target: 30.0,
            score: 25,
          },
        ],
      },
    ],
  },

  {
    id: 2,
    code: "MEC-ENGINEER",
    program_degree_name: "Mechanical Engineering",
    semester: [
      {
        semester__id: 2,
        semester__name: "spring",
        academicYear__id: 2,
        academicYear__name: "2024/2025",
      },
    ],
    PLOs: [
      {
        id: 4,
        name: "P1",
        code: "P1",
        score: 62.97,
        target: 30.0,
        plo_indicators: [
          {
            id: 5,
            name: "PI11",
            code: "PI11",
            weight: 30,
            target: 20.0,
            score: 50,
          },
          {
            id: 6,
            name: "PI12",
            code: "PI12",
            weight: 70,
            target: 60.0,
            score: 50,
          },
        ],
      },
      {
        id: 5,
        name: "P2",
        code: "P2",
        score: null,
        target: 20.0,
        plo_indicators: [
          {
            id: 7,
            name: "PI21",
            code: "PI21",
            weight: 30,
            target: 20.0,
            score: 30,
          },
        ],
      },
      {
        id: 6,
        name: "P3",
        code: "P3",
        score: null,
        target: 50.0,
        plo_indicators: [
          {
            id: 8,
            name: "PI31",
            code: "PI31",
            weight: 40,
            target: 30.0,
            score: 40,
          },
        ],
      },
    ],
  },
];

const output = [
  {
    plo_code: "P1",
    plo_indicators_scores_per_semester: [
      {
        plo_indicator_code: "PI11",
        name: "PI11",
        target: 20,
        "fall_2023/2024": 10,
        "spring_2024/2025": 50,
      },
      {
        plo_indicator_code: "PI12",
        name: "PI12",
        target: 60,
        "fall_2023/2024": 50,
        "spring_2024/2025": 50,
      },
    ],
  },
  {
    plo_code: "P2",
    plo_indicators_scores_per_semester: [
      {
        plo_indicator_code: "PI2",
        name: "PI2",
        target: 20,
        "fall_2023/2024": 15,
        "spring_2024/2025": 30,
      },
    ],
  },
  {
    plo_code: "P3",
    plo_indicators_scores_per_semester: [
      {
        plo_indicator_code: "PI3",
        name: "PI3",
        target: 30,
        "fall_2023/2024": 25,
        "spring_2024/2025": 40,
      },
    ],
  },
];
