import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewAnnualProgramReport,
  fetchAnnualProgramReports,
  addReport,
} from "../../reduxToolkit/features/AnnualProgramReport/AnnualProgramReportSlice";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { notification } from "antd";
import Button from "components/CustomButtons/Button.js";
import { useHistory } from "react-router-dom";
import ErrorModal from "./ErrorModal.jsx";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import {
  fetchProgramById,
  fetchProgramsByCode,
} from "../../reduxToolkit/features/program/ProgramsSlice";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import validationStyles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import Close from "@material-ui/icons/Close";
import { Analysis } from "views/Pages/Improvement/SSR/reportUtils";
import Select from "@material-ui/core/Select";
import { generateABETReport } from "../Pages/Improvement/SSR/reportUtils";

const useStyles = makeStyles(styles);
const useValidationStyles = makeStyles(validationStyles);

export default function AnnualProgramReportModal(props) {
  const classes = useStyles();
  const validationClasses = useValidationStyles();

  const defaultReportAccreditationBody = "NCAAA";
  const reportAccreditationBodies = ["NCAAA", "CTI", "ABET"];

  const defaultReportType = "Annual Program Report";
  const reportTypes = ["Annual Program Report", "Self Study Report"];

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const [type, setType] = useState(defaultReportType);
  const [accreditationBody, setAccreditationBody] = useState(
    defaultReportAccreditationBody
  );

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [handledError, setHandledError] = useState("");
  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState(null);
  const [numberState, setnumberState] = useState("");

  const verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  const handleChangeType = (event) => {
    setType(event.target.value);
  };
  const handleChangeAccreditationBody = (event) => {
    setAccreditationBody(event.target.value);
  };

  const openNotificationWithIcon = (type, msg) => {
    notification[type]({
      message: type,
      description: msg,
      top: 50,
      duration: 2,
    });
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchProgram = async (id) => {
    try {
      const program = await dispatch(fetchProgramById(id)).unwrap();
      await dispatch(fetchProgramsByCode(program.code)).unwrap();
    } catch (err) {
      setHandledError(err);
      handleOpenErrorModal();
    }
  };
  const program = useSelector((state) => state.program.program);
  const programsByCode = useSelector((state) => state.program.programsByCode);

  console.log("programsByCode", programsByCode);
  const { ProgramID } = props;

  const getReportPath = (type) => {
    switch (type) {
      case "Annual Program Report":
        return "annualProgram";
      case "Self Study Report":
        return "SSR";
      default:
        return "";
    }
  };

  const handleSend = async () => {
    if (accreditationBody === "ABET") {
      if (ProgramID) {
        // Call Program API
        await fetchProgram(ProgramID);
        // Generate ABET Report
        if (program) {
          const report = generateABETReport(program, programsByCode);
          if (version == null || version === "") {
            setnumberState("error");
          } else {
            // Create ABET Report
            try {
              const originalPromiseResult = await dispatch(
                addReport({
                  report_body: JSON.stringify(report),
                  body: "ABET",
                  type,
                  resource: "program",
                  resource_id: ProgramID,
                  version,
                  assign_to: program?.coordinators_detail?.[0]?.id,
                })
              ).unwrap();
              // handle result here
              handleClose();
              openNotificationWithIcon(
                "success",
                "ABET Report successfully added"
              );
              history.push(`/citrine/reports/ABET/${getReportPath(type)}`);
            } catch (rejectedValueOrSerializedError) {
              // handle error here
              console.log(
                "-----------rejectedValueOrSerializedError--------------",
                rejectedValueOrSerializedError
              );
              handleClose();
              setHandledError(rejectedValueOrSerializedError);
              handleOpenErrorModal();
            }
          }
        }
      }
    } else {
      if (ProgramID) {
        const resultAction = await dispatch(
          addNewAnnualProgramReport({ program_id: ProgramID })
        );
        if (addNewAnnualProgramReport.fulfilled.match(resultAction)) {
          openNotificationWithIcon("success", ` successfully Added`);
          history.push(`/citrine/reports/${type}/annualProgram`);
          dispatch(fetchAnnualProgramReports());
          handleClose();
        } else {
          if (resultAction.payload) {
            setHandledError(resultAction.payload);
            handleOpenErrorModal();
            handleClose();
          } else {
            setHandledError(resultAction.error);
            handleOpenErrorModal();
            handleClose();
          }
        }
      }
    }
  };

  return (
    <div>
      <MenuItem
        onClick={(event) => {
          handleClickOpen(event);
        }}
      >
        <h6>{t("Create Program Report")}</h6>
      </MenuItem>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {t("Create Program Report")}
        </DialogTitle>
        <DialogContent>
          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel
                className={
                  classes.labelHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Type
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <RadioGroup
                aria-label="Type"
                name="Type"
                value={type}
                onChange={handleChangeType}
              >
                {reportTypes.map((reportType) => (
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={reportType}
                          name={reportType}
                          aria-label={reportType}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={reportType}
                    />
                  </div>
                ))}
              </RadioGroup>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel
                className={
                  classes.labelHorizontal +
                  " " +
                  classes.labelHorizontalRadioCheckbox
                }
              >
                Accreditation body
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <RadioGroup
                aria-label="Accreditation Body"
                name="Accreditation Body"
                value={accreditationBody}
                onChange={handleChangeAccreditationBody}
              >
                {reportAccreditationBodies.map((accreditationBody) => (
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          value={accreditationBody}
                          name={accreditationBody}
                          aria-label={accreditationBody}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                        root: classes.labelRoot,
                      }}
                      label={accreditationBody}
                    />
                  </div>
                ))}
              </RadioGroup>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={4}>
              <FormLabel className={validationClasses.labelHorizontal}>
                Version
              </FormLabel>
            </GridItem>
            <GridItem xs={12} sm={8}>
              <CustomInput
                success={numberState === "success"}
                error={numberState === "error"}
                id="version"
                // formControlProps={{
                //   fullWidth: true
                // }}
                inputProps={{
                  onChange: (event) => {
                    if (verifyNumber(event.target.value)) {
                      setnumberState("success");
                    } else {
                      setnumberState("error");
                    }
                    setVersion(event.target.value);
                  },
                  type: "number",
                  endAdornment:
                    numberState === "error" ? (
                      <InputAdornment position="end">
                        <Close className={validationClasses.danger} />
                      </InputAdornment>
                    ) : undefined,
                }}
              />
            </GridItem>
          </GridContainer>

          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              style={{ paddingRight: "30px" }}
              color="danger"
            >
              {t("Cancel")}
            </Button>
            <Button
              onClick={() => {
                handleSend();
              }}
              color="info"
            >
              {t("Confirm")}
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <ErrorModal
        open={openErrorModal}
        handleClose={handleCloseErrorModal}
        handledError={handledError}
      />
    </div>
  );
}
